'use client';

import type {PropsWithChildren} from 'react';
import React, {useEffect, useState} from 'react';
import {RemoveScroll} from 'react-remove-scroll';
import clsx from 'clsx';
import {motion, useMotionValueEvent, useScroll} from 'motion/react';

import {useHero} from '@/modules/vaag/context/hero-context';

export const NavbarContainer = ({children}: PropsWithChildren) => {
	const {hasVideoHero} = useHero();
	const {scrollY} = useScroll();

	const [hidden, setHidden] = useState(false);
	const [prev, setPrev] = useState(0);
	const [bgColor, setBgColor] = useState('transparent');
	const [scrollIsAtTop, setScrollIsAtTop] = useState(true);

	const scrollMargin = 70;

	useMotionValueEvent(scrollY, 'change', (latest) => {
		if (latest > prev && latest > scrollMargin) {
			setHidden(true);
			setScrollIsAtTop(false);
		} else {
			setHidden(false);
			if (latest > scrollMargin) {
				setBgColor('bg-beige-100');
			}
		}
		if (latest === 0) {
			setBgColor('transparent');
			setScrollIsAtTop(true);
		}
		setPrev(latest);
	});

	// If focus is set on the logo or hamburger, show the navbar
	useEffect(() => {
		const handleFocus = () => setHidden(false);
		document.addEventListener('focus', handleFocus, true);
		return () => document.removeEventListener('focus', handleFocus, true);
	}, []);

	return (
		<motion.div
			initial={{y: 0}}
			animate={{
				y: hidden ? '-100%' : 0,
			}}
			transition={{duration: 0.2}}
			className={clsx(
				'z-40',
				'fixed',
				'top-0',
				'left-0',
				'right-0',
				'transition-colors',
				scrollIsAtTop && hasVideoHero ? 'text-beige-100' : 'text-primary',
				bgColor,
				RemoveScroll.classNames.fullWidth,
			)}
		>
			{children}
		</motion.div>
	);
};
